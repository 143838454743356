import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const GetBrands = createAsyncThunk(
  'giftCards/getBrands',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/gift-card/get-brands', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }

      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetGiftCards = createAsyncThunk(
  'giftCards/getGiftCards',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/gift-card/get-gift-cards', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }

      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetOrders = createAsyncThunk(
  'giftCards/getOrders',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/gift-card/get-orders', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }

      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetPaymentMethods = createAsyncThunk(
  'giftCards/getPaymentMethods',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/gift-card/get-payment-methods');

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }

      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const MarkAsFavouriteGiftCard = createAsyncThunk(
  'giftCards/markAsFavoriteGiftCard',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/gift-card/mark-as-favourite-gift-card', data);

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: error.response.data.message,
            status: error.response.status
          }
        })
      }

      return thunkAPI.rejectWithValue({
        error: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const giftCard = createSlice({
  name: 'giftCards',
  initialState: {
    success: false,
    loading: false,
    giftCards: [],
    totalGiftCards: 0,
    orders: [],
    totalOrders: 0,
    error: '',
    message: '',
    paymentMethods: [],
    brands: [],
    markedIsFavourite: false
  },
  reducers: {
    SetGiftCardState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    clearState(state, action) {
      return {
        ...state
      };
    }
  },
  extraReducers: {
    [GetBrands.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetBrands.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      brands: action.payload.brands
    }),
    [GetBrands.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [GetGiftCards.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetGiftCards.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      totalGiftCards: action.payload.totalGiftCards,
      giftCards: action.payload.giftCards
    }),
    [GetGiftCards.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [GetOrders.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetOrders.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      totalOrders: action.payload.totalOrders,
      orders: action.payload.orders
    }),
    [GetOrders.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [GetPaymentMethods.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetPaymentMethods.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      paymentMethods: action.payload.paymentMethods
    }),
    [GetPaymentMethods.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [MarkAsFavouriteGiftCard.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [MarkAsFavouriteGiftCard.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message,
      markedIsFavourite: action.payload.isFavourite
    }),
    [MarkAsFavouriteGiftCard.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    })
  }
});

const { reducer, actions } = giftCard;

export const {
  clearState,
  SetGiftCardState
} = actions;

export default reducer;
