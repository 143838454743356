import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { TableWrapper } from './style'
import Spin from '../Spin';

const Index = (props) => {
  const { gridReady, defaultColDef,rowSelection, columnDefinitions, rowData, sidebarOpen, height, rowHeight, onSortChange, headerHeight, onFilterChanged, loading,
     onSelectionChanged, setSelected, isRowSelectable } = props;
  return (
    <TableWrapper height={height} loading = {loading}>
      <div className={` ag-theme-alpine ag-theme-balham QB-dataTable`} ß='true'>
        <AgGridReact
          reactNext={true}
          onGridReady={gridReady}
          deltaRowDataMode={false}
          animateRows={true}
          defaultColDef={defaultColDef}
          columnDefs={columnDefinitions}
          onSortChanged={onSortChange}
          rowData={rowData}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          enableCellTextSelection={true}
          rowSelection={rowSelection}
          // getRowNodeId={({ _id }) => _id}
          pagination={false}
          sideBar={sidebarOpen ? "columns" : false}
          enableRangeSelection={true}
          suppressRowClickSelection={true}
          suppressScrollOnNewData={true}
          onFilterChanged = {onFilterChanged}
          onSelectionChanged={onSelectionChanged}
          rowMultiSelectWithClick={true}
          setSelected = {setSelected}
          isRowSelectable={isRowSelectable}
                // Suppress sorting so the provided order is maintained
          suppressSorting={true}

          paginationPageSize={10}>
        </AgGridReact>
      </div>
    </TableWrapper>
  );
};
export default Index;
