import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';
const axios = axiosBaseUrl();

export const SignUp = createAsyncThunk(
  'auth/signUp',
  async (data, { rejectWithValue }) => {
    try {
      const {
        firstName,
        lastName,
        email,
        password,
        signUpViaInvite,
        keepaKey,
        partner_key,
        partnero_referral,
        selectedPlan
      } = data;

      const response = await axios.post('/auth/sign-up', {
        firstName,
        lastName,
        email,
        password,
        signUpViaInvite,
        keepaKey,
        partner_key,
        partnero_referral,
        selectedPlan
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const SignIn = createAsyncThunk(
  'auth/signIn',
  async (data, { rejectWithValue }) => {
    try {
      const { email, password } = data;
      const response = await axios.post('/auth/sign-in', { email, password });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const ForgetPassword = createAsyncThunk(
  'auth/forgetPassword',
  async (data, { rejectWithValue }) => {
    try {
      const { email, password } = data;
      const response = await axios.post('/auth/forget-password', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const ResetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (user, thunkAPI) => {
    try {
      const response = await axios.post('/auth/reset-password', user);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data,
            status: err.response.status
          }
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const RegisterSubUser = createAsyncThunk(
  'auth/registeredSubUser',
  async (user, thunkAPI) => {
    try {
      const response = await axios.post('/auth/register-sub-user', user);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data,
            status: err.response.status
          }
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const OidcLoginUpdate = createAsyncThunk(
  'auth/oidcLoginUpdate',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/auth/oidc-login-update', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data,
            status: err.response.status
          }
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const SendEmailVerification = createAsyncThunk(
  'auth/SendEmailVerification',
  async (user, thunkAPI) => {
    try {
      const response = await axios.post('/auth/send-email-verification', user);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data,
            status: err.response.status
          }
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const keepaKeyValidation = createAsyncThunk(
  'auth/keepaKeyValidation',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/user/keepa-key-validation', {
        params: {
          key: data.key,
          userId: data.userId
        }
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const ConfirmEmailVerification = createAsyncThunk(
  'auth/emailVerification',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/auth/email-verification', {
        params: {
          userId: data.userId
        }
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data,
            status: err.response.status
          }
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const auth = createSlice({
  name: 'auth',
  initialState: {
    error: '',
    loading: false,
    message: '',
    success: false,
    token: '',
    user: {},
    resetPasswordEmail: '',
    storeStatus: '',
    storesCount: 0,
    keepaError: '',
    keepaSuccess: false,
    keepaValid: false,
    selectedPlan: {}
  },
  reducers: {
    SetAuthState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    LogOut: () => ({
      error: '',
      loading: false,
      message: '',
      success: false,
      token: '',
      user: {},
      storesCount: 0,
      storeStatus: '',
      keepaError: '',
      keepaSuccess: false,
      keepaValid: false,
      selectedPlan: {}
    })
  },
  extraReducers: {
    [ForgetPassword.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [ForgetPassword.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      success: true,
      loading: false
    }),
    [ForgetPassword.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      success: true,
      loading: false
    }),
    [SignIn.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [SignIn.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message,
      token: action.payload.token,
      user: action.payload.user,
      storesCount: action.payload.storesCount,
      storeStatus: action.payload.storeStatus,
      selectedPlan: action.payload.selectedPlan
    }),
    [SignIn.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      loading: false,
      success: true
    }),
    [SignUp.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [SignUp.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message,
      token: action.payload.token.token,
      user: action.payload.user
    }),
    [SignUp.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      error: action.payload.err.message
    }),
    [ResetPassword.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [ResetPassword.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      loading: false,
      success: true
    }),
    [ResetPassword.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err.message,
      success: true,
      loading: false
    }),
    [RegisterSubUser.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [RegisterSubUser.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      loading: false,
      success: true
    }),
    [RegisterSubUser.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err.message,
      success: true,
      loading: false
    }),
    [OidcLoginUpdate.pending]: (state, action) => ({
      ...state,
      success: false
    }),
    [OidcLoginUpdate.fulfilled]: (state, action) => ({
      ...state,
      success: true
    }),
    [OidcLoginUpdate.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err.message,
      success: true
    }),
    [SendEmailVerification.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [SendEmailVerification.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      loading: false,
      success: true
    }),
    [SendEmailVerification.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      success: true,
      loading: false
    }),
    [keepaKeyValidation.pending]: (state, action) => ({
      ...state,
      keepaSuccess: false,
      loading: true
    }),
    [keepaKeyValidation.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      message: action.payload.message,
      keepaSuccess: true,
      keepaValid: true
    }),
    [keepaKeyValidation.rejected]: (state, action) => ({
      ...state,
      keepaError: action.payload.err?.message,
      keepaSuccess: true,
      keepaValid: false,
      loading: false
    }),
    [ConfirmEmailVerification.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [ConfirmEmailVerification.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      message: action.payload.message,
      success: true,
    }),
    [ConfirmEmailVerification.rejected]: (state, action) => ({
      ...state,
      error: action.payload.error.message,
      success: true,
      loading: false
    })
  }
});

const { reducer, actions } = auth;

export const { SetAuthState, LogOut } = actions;

export default reducer;
