import React, { useCallback, useEffect, useState } from 'react'
import { Button, Segmented   } from 'antd'
import { FaArrowDownLong } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { FcCancel } from 'react-icons/fc';

import Modal from '../modal'
import Input from '../inputs/input'
import Spin from '../Spin'
import { SubscriptionPlanCardWrapper } from './style'
import {
  SetAuthState
} from '../../redux/slices/auth-slice';
import { SetUserState, GetUser, CancelSubscription } from '../../redux/slices/user-slice';
import {
  GetSubscriptionPlans,
  CreateSubscription,
  GetCouponDetails,
  SetSubscriptionState,
} from '../../redux/slices/subscription-slice';
import { plansData } from '../../utils/helpers';
import Notification from '../notifications/notification';
import { HUNDERED_UPC_STORES, FIFTY_UPC_STORES } from '../../constants/constants';

const SubscriptionPlanCard = ({data, heading, labelText, className, subscriptionType, plan}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    message,
    success,
    error,
    loading,
    subscriptionPlans,
    coupon
  } = useSelector(state => (state.subscription));

  const {
    user: userData,
    selectedPlan
  } = useSelector((state) => state.auth);
  const { user, impersonateUserData } = useSelector(state => (state.user));

  const [couponApplied, setCouponApplied] = useState(false)
  const [couponCode, setCouponCode] = useState('');
  const [plansPrices, setPlansPrices] = useState(plansData);
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const [updateSub, setUpdateSub] = useState(false);
  const [showSupportedStores, setShowSupportedStores] = useState(false);
  const [showMapData, setShowMapData] = useState(false);
  const [planData, setPlanData] = useState(null)

 const [planType, setPlanType] = useState(subscriptionType === heading? plan : 'monthly')

  const getCouponDetails = (planName) => {
    if (couponApplied && couponCode && selectedPlan.name === planName && planType !== 'yearly') {
      Notification({
        type: "success",
        title: "Success",
        description: 'Coupon already applied'
      });
      return;
    } else if (!couponCode.trim()) {
      dispatch(SetSubscriptionState({field:"error", value:"Pleaes Enter Coupon Code" }));
    } else if (couponCode.includes(' ')) {
      dispatch(SetSubscriptionState({field:"error", value:"Coupon Code does not contain white spaces" }));
    // } else if (heading === 'Lite') {
    //   dispatch(SetSubscriptionState({field:"error", value:"Coupon is not applicable on Lite plan!" }));
    } else if (planType === 'yearly' && heading !== 'Lite') {
      dispatch(SetSubscriptionState({field:"error", value:"Coupon is not applicable on yearly plan!" }));
    } else {
        if(['15DayExtended', '30DayExtended'].includes(couponCode)) {
          setCouponApplied(true)
        }
        
        dispatch(SetAuthState({ field: "selectedPlan", value: {
          name: planName,
          price: plansPrices[heading][planType]['price'],
          plan: planType
        } }));
      
      dispatch(GetCouponDetails({
        couponCode
      }))
    }
  }

  const hadleSelectPlan = ({
    name,
    price,
    plan
  }) => {
    setUpdateSub(false)
    if (!isEmpty(userData)) {
      const myPlan = subscriptionPlans.find(p => p.subscriptionName === name && p.interval === plan);
      if (myPlan) {
        const {
          priceId
        } = myPlan
        dispatch(SetAuthState({ field: "selectedPlan", value: {
          name,
          price,
          plan
        } }));
        // if (name === 'Standard' || name === 'Growth') {
        //   let coupon = null;
        //   if (couponCode && couponCode !== 'null') {
        //     coupon = couponCode;
        //   }
        //   history.push(`/store-based-subscription/${priceId}/${name}/${coupon}/${true}`)
        // } else 
        if (user?.payment) {
          dispatch(CreateSubscription({
            impersonateUserId: impersonateUserData.userId,
            priceId,
            subscriptionPlan: plan,
            subscriptionCreatingFirstime: true,
            subscriptionType: name,
            couponCode,
            // subscribedStores: name === "Standard" ? FIFTY_UPC_STORES : name === "Growth" ? HUNDERED_UPC_STORES : []
          }));
        } else {
          dispatch(SetUserState({
            field: 'subscriptionData', value: {
              priceId,
              subscriptionCreatingFirstime: true,
              subscriptionPlan: plan,
              subscriptionType: name,
              couponCode,
              // subscribedStores: name === "Standard" ? FIFTY_UPC_STORES : name === "Growth" ? HUNDERED_UPC_STORES : []
            }
          }));
          history.push('/auth/payment');
        }
      }
    } else {
      dispatch(SetAuthState({ field: "selectedPlan", value: {
        name,
        price,
        plan
      } }));
      history.push('/auth/sign-up')
    }
  }
  
  const handleCancelSubscription = () => {
    dispatch(CancelSubscription({ impersonateUserId: impersonateUserData.userId }));
    setCancelSubscriptionModal(false);
  };

  useEffect(() => {
    if ((coupon?.couponDiscount || coupon?.percent_off) && coupon?.coupon === couponCode && (planType !== 'yearly' || heading === 'Lite')) {
      let yearlPrice = plansPrices[selectedPlan.name]['yearly']['originalPrice']
      let monthlyPrice = plansPrices[selectedPlan.name]['monthly']['originalPrice']
      if (coupon.percent_off) {
        yearlPrice =  yearlPrice - ((coupon?.percent_off/100) * yearlPrice)
        monthlyPrice =  monthlyPrice - ((coupon?.percent_off/100) * monthlyPrice)
      } else {
        yearlPrice =  yearlPrice - (coupon?.couponDiscount || 0)
        monthlyPrice =  monthlyPrice - (coupon?.couponDiscount || 0)
      }
    
      if (heading === 'Lite' && planType === 'yearly') {
        setPlansPrices({
          ...plansPrices,
          [selectedPlan.name]:{
            ...plansPrices[selectedPlan.name],
            yearly: {
              ... plansPrices[selectedPlan.name]['yearly'],
              price: yearlPrice
            }
          }
        });
      } else {
        setPlansPrices({
          ...plansPrices,
          [selectedPlan.name]:{
            ...plansPrices[selectedPlan.name],
            monthly: {
              ... plansPrices[selectedPlan.name]['monthly'],
              price: monthlyPrice
            }
          }
        });
      }
      if (selectedPlan.name === heading){
        setCouponApplied(true)
      }
      dispatch(SetSubscriptionState({ field: 'success', value: false }));
    }
  }, [coupon, couponCode]);

  useEffect(() => {
    if (success && message) {
      Notification({
        type: "success",
        title: "Success",
        description: !message.includes('Coupon') ? 'Subscription Created Successfully.': message,
        key:1
      });
      if (message.includes('Subscription Created ')) {
        dispatch(GetUser({
          impersonateUserId: impersonateUserData.userId
        }));
        setCouponApplied(false),
        setCouponCode('')
      }
      dispatch(SetSubscriptionState({ field: 'success', value: false }));
      dispatch(SetSubscriptionState({ field: 'message', value: '' }));
    }
  }, [message, success]);

  useEffect(() => {
    if (user && user.subscriptionId && user.subscriptionPlan && location.pathname !== '/profile') {
      dispatch(SetAuthState({ field: 'user', value: user }));
      history.push('/auth/congratulations');
    }
  }, [user])

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error,
        key:1
      });

      dispatch(SetSubscriptionState({ field: 'error', value: '' }));
    }
  }, [error]);
  return (
    <SubscriptionPlanCardWrapper isEmptyStyle={!isEmpty(userData)} onClick={() => {
      dispatch(SetAuthState({ field: "selectedPlan", value: {
        name: heading,
        price: plansPrices[heading][planType]['price'],
        plan: planType
      }}));
    }}>
      <div className={`card-overlay-wrapper ${(selectedPlan?.name == heading) || subscriptionType === heading ? className: ''}`}>
        <div className='card-header'>
        {((((couponCode === coupon?.coupon) && heading === selectedPlan?.name) || ['15DayExtended', '30DayExtended'].includes(couponCode)) && (planType !== 'yearly' || heading === 'Lite') && couponApplied) ? <span className="bag-wrapper coupon-ui-wrapper">{couponCode} Applied</span> : null}
        {(subscriptionType === heading && planType === plan)  ? <span className="bag-wrapper active-badge-ui-wrapper">Active</span> : null}
         <h3>{heading}</h3>
        <Segmented
          className='qf-segment-wrapper'
          options={['monthly', 'yearly']}
          onChange={(value) => {
            setPlanType(value)
            dispatch(SetAuthState({ field: "selectedPlan", value: {
              name:heading,
              price:plansPrices[heading][value]['price'],
              plan: value
            }}));

          }}
          value={planType}
        />
         
          {<p className={`label ${(heading === 'Standard' || heading === 'Growth') ? 'highlight':''}`} onClick={() => {
            if (heading === 'Standard' || heading === 'Growth') {
              setShowSupportedStores(true);
            }
          }}>{labelText}</p>}
          <div className='price-wrapper'><p>${plansPrices[heading][planType][couponCode === coupon?.coupon ? 'price' : 'originalPrice']}</p><span>/{plansPrices[heading][planType]['text']}</span></div>
        </div>
        <h6 onClick={() => {
          if (heading !== 'Lite') {
            setShowMapData(true)
          }
        }}>Included: {heading !== 'Lite' ? <span><span style={{color: 'blue', textDecoration: 'underline'}}>M.A.P.S</span> Data</span> : null}</h6>
        <div>
        { heading === 'Lite' && <ul>
          {data?.map((item, index) => (
            <li key={index}>{item.text}</li>
          ))}
        </ul>}
        {!isEmpty(userData) ? <div className="d-flex align-items-center gap-2 card-header-subscription">
        <Input type="text" width="275px" placeholder="Enter coupon code" onChange={(e) => setCouponCode(e.target.value.trim())} value ={couponCode} />
         <Button className="coupon-button" onClick={() => {
              getCouponDetails(heading)
            }}
         >Apply Coupon
          </Button>
         </div> : null}
         {(subscriptionType === heading && planType === plan && !user?.userActiveTill) ? <Button onClick={() => setCancelSubscriptionModal(true)}>Cancel Subscription</Button>: <Button onClick={() =>{
          setUpdateSub(true);
          setPlanData({
            name: heading,
            plan: selectedPlan.name === heading ? selectedPlan.plan : planType,
            price: plansPrices[heading][selectedPlan.name === heading ? selectedPlan.plan : planType]['price']
          })
        }}>Subscribe</Button>}
        </div>
      </div>
      {loading && <Spin />}
      <Modal
        className="cancel-modal"
        open={cancelSubscriptionModal}
        saveText="Cancel Subscription"
        closeText="Cancel"
        onSave={() => handleCancelSubscription()}
        onClose={() => setCancelSubscriptionModal(false)}
      >
        <div className="category-modal-wrapper store-modal-wrapper">
          <FcCancel className="icon-popup" />
          <h2>Are you sure want to cancel the subscription?</h2>
        </div>
      </Modal>
      <Modal
        className="cancel-modal"
        open={updateSub}
        saveText="Update Subscription"
        closeText="Cancel"
        onSave={() => hadleSelectPlan(planData)}
        onClose={() => setUpdateSub(false)}
      >
        <div className="category-modal-wrapper store-modal-wrapper">
          <h2>Are you sure want to update the subscription?</h2>
        </div>
      </Modal>
      <Modal
        open={showSupportedStores}
        closeText="Close"
        size = 'large'
        onClose={() => setShowSupportedStores(false)}
      >
        <div className="category-modal-wrapper store-modal-wrapper">
        <div className='stores-container'>
          {heading === 'Standard' ? FIFTY_UPC_STORES?.sort().map((item, index) => (
            <li style={{textAlign: 'left', color: '#318CE7', cursor: 'pointer'}} onClick={() => window.open(`https://${item.includes('maxx')? '': 'www.'}${item.toLowerCase()}.com`, '_blank')} key={index}>{item}</li>
          )): HUNDERED_UPC_STORES?.sort().map((item, index) => (
            <li style={{textAlign: 'left', color: '#318CE7', cursor: 'pointer'}} onClick={() => window.open(`https://${item.includes('maxx')? '': 'www.'}${item.toLowerCase()}.com`, '_blank')} key={index}>{item}</li>
          ))}
        </div>
        </div>
      </Modal>
      <Modal
        className="cancel-modal"
        open={showMapData}
        closeText="Close"
        onClose={() => setShowMapData(false)}
      >
        <div className="category-modal-wrapper store-modal-wrapper">
        <h2>Included M.A.P.S Data</h2>
        <ul>
          {data?.sort((a, b) => a.text.localeCompare(b.text)).map((item, index) => (
            <li style={{textAlign: 'left'}} key={index}>{item.text}</li>
          ))}
          {
            heading !== 'Lite' ? ['Standard', 'Growth'].includes(heading) ? <li style={{textAlign: 'left'}} key='xyz'>Upto 2 Dashboard only sub-users</li> : <>
            <li style={{textAlign: 'left'}} key='xyz2'>Upto 3 Extension sub-users</li> <li style={{textAlign: 'left'}} key='xyz3'>Upto 3 Dashboard only sub-users</li>
            </> : null 
          }
        </ul>
        </div>
      </Modal>
    </SubscriptionPlanCardWrapper>
  )
}

export default React.memo(SubscriptionPlanCard)
