import Styled from "styled-components";
import NodataImg from "../../assets/images/nodata.svg";
const TableWrapper = Styled.div`
position: relative;
.QB-dataTable{
    height: ${(props) => `calc(100vh - ${props.height})`};
    font-family: 'Lato', sans-serif;
    padding-top: 0px;
    padding-left: 0px;
}
.ag-pinned-right-header {
        background: #81b0fe47;
        border-left: none;
        }
    .ag-pinned-right-cols-container {
    margin: 0px !important;
    .ag-cell-first-right-pinned {
        background: #81b0fe47;
    }
}
.ag-cell-first-right-pinned{
    border-left: none !important;

}
.ag-pinned-right-cols-container 
.QB-dataTable.drawer-table{
    height: calc(100vh - 766px);
    padding-top: 25px;
    padding-left: 5px;
}
.QB-dataTable .ag-header{
    font-family: 'Lato', sans-serif;
    border-bottom-color:#E7EAE9;
}
.QB-dataTable .ag-header-container{
    z-index: 1;
    background: #F8F8F8;
}
.ag-header-cell-label{
    white-space:normal;
}
.QB-dataTable{
    background: #F9FAFC;
    .ag-root{
        width:100% ;
        background: #F9FAFC;
    }
    .ag-root-wrapper{
        border:none
    }
}
.QB_dataTable .ag-cell{
    border-bottom:1px solid ${({ theme }) => theme["secondary-color"]};
}
.QB-dataTable .ag-header-viewport{
    border-radius: 4px 4px 0px 0px;
}
.QB-dataTable .ag-header-viewport .ag-header-cell{
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    padding: 10px 16px;
    color:${({ theme }) => theme["label-color"]};
    .ag-cell-label-container{
        padding: 0;
    }
}
.QB-dataTable .ag-header-viewport .ag-header-cell::after{
    border-right:none;
}
.QB-dataTable .ag-center-cols-viewport .ag-cell-value{
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    display: flex;
    color:${({ theme }) => theme["body-text"]};
    align-items:flex-start;
    display:block;
}
.QB-dataTable .ag-cell-focus{
    border-color:transparent!important;
}
.QB-dataTable .ag-center-cols-viewport .ag-cell-wrapper{
    height: auto;
    min-height: 67px;
    padding: 0 0px;
    align-items: center;
    /* padding: 13px 0px 0!important; */
}
.QB-dataTable  .ag-row-even,
.QB-dataTable  .ag-row-odd,
.QB-dataTable  .ag-row{
    border-bottom-color: #EEF1F5;
    background-color: #F9FAFC;

.ag-cell{
    &:last-of-type{
        padding-right:0;
    }
}
}

.QB-dataTable  .ag-paging-panel{
background-color: #F8FAFB;
}
.QB-dataTable .ag-paging-button{
    border-left: 1px solid #C4C4C4;
    padding: 8px 8px 8px 10px;
    color: #1565D8;
}
.QB-dataTable .ag-paging-button .ag-icon{
    font-weight: bold;
}
.QB-dataTable .ag-tool-panel-wrapper{
    width: 320px;
}
.QB-dataTable .ag-rtl .ag-side-bar-left,
.QB-dataTable .ag-ltr .ag-side-bar-right{
    border: top 1px;

}
.QB-dataTable .ag-overlay
{
    background: #FFFFFF;
    .ag-overlay-wrapper{
        padding-top:38px;
        &.ag-overlay-no-rows-wrapper{
            &:before{
                content:url(${props => props.loading ? "" : NodataImg});
                @media screen and (max-height:700px){
                    transform:scale(0.7);
                }
            }
            span{
                display:none;
            }
        }
    } 
}
`;
export { TableWrapper };
