import crypto from 'crypto';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Button from '../../component/Button/Index';
import Input from "../../component/inputs/input/index";
import Notification from '../../component/notifications/notification';
import PageHeader from "../../component/header";
import Spin from '../../component/Spin';

import ArrowLeft from "../../assets/images/arrow-left.svg";
// North America Region
import US from "../../assets/images/flags/US.svg";
import CA from "../../assets/images/flags/CA.svg";
import MX from "../../assets/images/flags/MX.svg";
import BR from "../../assets/images/flags/BR.svg";
// Europe Region
// GROUP 1
import UK from "../../assets/images/flags/UK.svg";
import SP from "../../assets/images/flags/SP.svg";
import FR from "../../assets/images/flags/FR.svg";
import BG from "../../assets/images/flags/BG.svg";
import NL from "../../assets/images/flags/NL.svg";
import GM from "../../assets/images/flags/GM.svg";
import IT from "../../assets/images/flags/IT.svg";
import SW from "../../assets/images/flags/SW.svg";
import GL from "../../assets/images/flags/GL.svg";
// GROUPS
import TR from "../../assets/images/flags/TR.svg";
import SA from "../../assets/images/flags/SA.svg";
import UAE from "../../assets/images/flags/UAE.svg";
import IN from "../../assets/images/flags/IN.svg";
import EG from "../../assets/images/flags/EG.svg";
import Form from "react-bootstrap/Form";
// EAST REGION
import JP from "../../assets/images/flags/JP.svg";
import AU from "../../assets/images/flags/AU.svg";
import SG from "../../assets/images/flags/SG.svg";

import {
  GetStores,
  SetStoreState,
  amazonStoreInfo
} from '../../redux/slices/store-slice';

import {
  SetAuthState,
  keepaKeyValidation,
  LogOut
} from '../../redux/slices/auth-slice';

import {
  APP_ID,
  MARKETPLACE_TO_REGEION_MAPPING
} from '../../constants/constants';

import { StoreConnectivity } from "../authOnboarding-style";
import { StoreWrapper } from './style';
import { Radio } from '@mui/material';

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [storeName, setStoreName] = useState('');
  const [region, setRegion] = useState('na');
  const [keepaKeyValue, setKeepaKeyValue] = useState('');

  const { impersonateUserData } = useSelector(state => state.user);
  const {
    storesData,
    error,
    loading
  } = useSelector((state) => state.store);

  const {
    token,
    storeStatus,
    error: keepaErr,
    message: authMessage,
    user: userData,
    keepaSuccess,
    keepaError,
    keepaValid,
    loading: authLoading,
    selectedPlan
  } = useSelector(state => (state.auth));

  const handleRegionSelect = (value) => {
    setRegion(value);
  };

  const handleAddStore = () => {
    if (!keepaKeyValue && selectedPlan?.name !== 'Lite') {
      dispatch(SetAuthState({ field: 'error', value: 'Keepa Api Key is required.' }));
    } else if (!region) {
      dispatch(SetStoreState({ field: 'error', value: 'Select Store Region' }));
    } else {
      if (userData?._id) {
        dispatch(keepaKeyValidation({ key: keepaKeyValue, userId: userData._id  }));
      } else if (userData?.userId) {
        dispatch(keepaKeyValidation({ key: keepaKeyValue, userId: userData.userId  }));
      }
    }
  };

  useEffect(() => {
    dispatch(GetStores({
      impersonateUserId: impersonateUserData.userId,
    }));
    const { keepaKey } = userData || {};

    if (keepaKey && keepaKey !== 'N/A') {
      setKeepaKeyValue(keepaKey)
    }
  } ,[]);

  useEffect(() => {
    if (error) {
      Notification({
        type: 'error',
        title: 'Error',
        description: error
      });
      dispatch(SetStoreState({ field: 'error', value: '' }));
      return;
    }
  }, [error]);

  useEffect(() => {
    if (keepaErr) {
      Notification({
        type: 'error',
        title: 'Error',
        description: keepaErr
      });
      dispatch(SetAuthState({ field: 'error', value: '' }));
      return;
    }
  }, [keepaErr]);

  useEffect(() => {
    if (keepaSuccess && keepaError) {
      Notification({
        type: "error",
        title: "Error",
        description: 'Enter a valid Keepa API Key.'
      });
      dispatch(SetAuthState({ field: "keepaError", value: "" }));
      dispatch(SetAuthState({ field: "keepaSuccess", value: false }));
      return;
    }

    if (keepaSuccess && keepaValid) {
      dispatch(SetAuthState({ field: "user", value: { ...userData, keepaKey: keepaKeyValue } }));
    }

    if (keepaSuccess && keepaValid) {
      const state = crypto.randomBytes(20).toString('hex');
      const selectedRegion = MARKETPLACE_TO_REGEION_MAPPING[region];

      dispatch(amazonStoreInfo({
        name: storeName,
        spApiState: state,
        spApiRegion: selectedRegion
      }));

      let url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${APP_ID}&state=${state}&version=beta&redirect_uri=https://qikfinds-extension.com/auth/sp-api/`;

      if (region === 'eu') {
        url = `https://sellercentral-europe.amazon.com/apps/authorize/consent?application_id=${APP_ID}&state=${state}&version=beta&redirect_uri=https://qikfinds-extension.com/auth/sp-api/`;
      }

      if (region === 'fe') {
        url = `https://sellercentral-japan.amazon.com/apps/authorize/consent?application_id=${APP_ID}&state=${state}&version=beta&redirect_uri=https://qikfinds-extension.com/auth/sp-api/`;
      }

      dispatch(SetAuthState({ field: "keepaSuccess", value: false }));
      dispatch(SetAuthState({ field: "keepaValid", value: false }));

      window.open(url, '_self');
      return;
    }
  }, [keepaSuccess, keepaError, keepaValid]);

  return (
    <>
      <StoreWrapper>
        <PageHeader />
        <StoreConnectivity>
          <div className="onboarding-title ">
            <h1 className="d-flex align-items-center">
              {storesData.length ? <img src={ArrowLeft} alt="arrow-left" onClick={() => history.goBack()}/> : null }
              Account Configuration
            </h1>
          </div>
          <div className="select-marketplace">
            <Row>
              <Col md={{ span: 4, offset: 1 }}>
              <div className="d-flex mb-5 justify-content-between justify-content-center mb-3 align-items-end add-bottom-spacing settings-title-bar">
                    <div className='mr-5'>
                    <p className='label-text-ui'>
                    1. Keepa Key <a href='https://keepa.com/#!api' target='_blank'>(Find it here)</a>
                    </p>
                      <Input
                        placeholder="Keepa API Key"
                        width='600px'
                        value = {keepaKeyValue}
                        onChange ={e => setKeepaKeyValue(e.target.value)}
                      />
                    </div>
                
                </div>
                {/* <div className="search-input mb-5">
                <p className='label-text-ui'>2. Enter Your Exact Amazon Store Name</p>
                  <Input
                    searchwitharrow placeholder="Enter Your Exact Amazon Store Name"
                    onChange={(e) => setStoreName(e.target.value)}
                    value={storeName}
                  />
                </div> */}
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <h3 className='label-text-ui'>2. Select Region from following</h3>
              </Col>
            </Row>
            <Row>
              <Col md={1}></Col>
              <Col md={2}>
                <div className="marketplace-region">
                 <div className='market-place-item'><Radio checked={region} onChange={e => setRegion('na')}/> <h4>North America Region</h4></div>
                  <div className="region-list region-list-wrapper">
                          <div className="region-country-list">
                            <img src={US} alt="country flag" />
                            <img src={CA} alt="country flag" />
                            <img src={MX} alt="country flag" />
                            <img src={BR} alt="country flag" />
                          </div>
                  </div>
                </div>
                <div className='add-button-space d-flex'>
                    <Button
                      className="outlined"
                      outlined
                      onClick={() => handleAddStore()}
                    >
                      Connect Amazon Account
                    </Button> 
                   {/* {selectedPlan?.name === 'Lite' ? <Button
                      className="outlined"
                      onClick={() => history.push('/auth/payment')}
                    >
                      Skip
                    </Button> : null} */}
                  </div>
              </Col>
            </Row>
          </div>
        </StoreConnectivity>
      </StoreWrapper>
      {
        (loading || authLoading) && (<Spin />)
      }
    </>
  );
};

export default Index;
