import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'universal-cookie';
import {
  Box,
  Collapse,
  CssBaseline,
  Divider,
  ListItemButton,
  List,
  Menu,
  MenuItem,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { FaShoppingCart } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import { MdDashboard } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { FaLink, FaGift } from "react-icons/fa6";
import { LuBan } from "react-icons/lu";
import { FaUserCircle } from "react-icons/fa";

import Logo from "../assets/images/logo_qikfinds_extension.svg";

import SidebarWrapper from "./style";

import { LogOut, OidcLoginUpdate } from "../redux/slices/auth-slice";
import { SetUserState } from "../redux/slices/user-slice";
import { SetStoreState } from "../redux/slices/store-slice";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const gcEmailLists = [
  'bizwithpurpose@gmail.com',
  'rdmtechnologygroup@hotmail.com',
  'rossmarecek@hotmail.com',
  'diegotex@gmail.com',
  'ron@rjrjrproducts.com',
  'shelbyhomerentals@gmail.com',
  'mb.retail.world@gmail.com',
  'nick@5-staroutlet.com',
  'achomali@ac2sales.com',
  'debi@debi-z.com',
  'serventerprises7@gmail.com',
  'gbgarvin1@gmail.com',
  'karl.godette@gmail.com',
  'kbhcoaching@outlook.com',
  'diego.a.rabelo@gmail.com'
];

const Layout = ({ children }) => {
  const cookie = new Cookies();
  const dispatch = useDispatch();
  const history = useHistory();
  const [versionDetails, setVersionDetails] = useState({
    success: false,
    value: 1
  })

  const { user } = useSelector(state => state.auth);
  const { impersonateUserData } = useSelector(state => state.user);

  const [open, setOpen] = useState(true);
  const [userName, setUserName] = useState('');

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const style = {
    pb: 0,
  };

  useEffect(() => {
    chrome.runtime?.sendMessage("hakjiagadepdajkkhjcplkdinlkgakal", { message: "version" },
      (res) => {
        if (res && res.success) {
          setVersionDetails({
            success: true,
            value: res.version
          });
        }
      });
  }, []);

  useEffect(() => {
    if (user?.name || impersonateUserData?.name) {
      let name;
      if (impersonateUserData.name) {
        name = impersonateUserData.name.split(' ')
      } else {
        name = user?.name.split(' ')
      }
      let shortName = name[0][0];
      if (name.length > 1) {
        shortName = `${shortName}${name[1][0]}`;
      }
      setUserName(shortName);
    }
  }, [user, impersonateUserData]);

  const handleEndImpersonation = () => {
    dispatch(SetUserState({ field: 'impersonateUserData', value: {
      userId: '',
      name: '',
      email: ''
    }}));

    dispatch(SetStoreState({ field: 'tab', value: 'users' }));
    history.push('/');
  };

  const handleLogout = () => {
    const { oidcLogin = false } = user || {};
    if (oidcLogin) {
      window.open('https://oidc.qikfinds-extension.com/oidc/session/end', '_blank');
      dispatch(OidcLoginUpdate({
        email: user.email,
        oidcLogin: 'false'
      }));
    }

    cookie.remove('qikFindsToken', { path: '/'});
    cookie.remove('keepaKey', { path: '/'});
    cookie.remove('isStoreExists', { path: '/'});

    dispatch(LogOut());
  };
  return (
    <SidebarWrapper>
      <Box id="qikfinds-layout">
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <DrawerHeader className="side-bar-header-wrapper">
            <IconButton className="icon-button" onClick={handleDrawerClose}>
              <FaBars />
            </IconButton>
            {open ? (
              <img
                src={Logo}
                alt="no-logo"
                style={{cursor: 'pointer'}}
                onClick={() => window.open('https://qiklists.podia.com/qikfinds-extension')}
              />
            ) : null}
          </DrawerHeader>
          <Divider />
          <List className="side-bar-list">
            <ListItemButton
              button="true"
              onClick={() => {history.push('/dashboard')}}
              selected={window.location.pathname === '/dashboard'}
            >
              <ListItemIcon>
                <MdDashboard className="menu-icon" />
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </ListItemButton>

            {(user?.role === 'admin' && !impersonateUserData.userId) ? <ListItemButton
              button="true"
              onClick={() => {history.push('/users')}}
              selected={window.location.pathname === '/users'}
            >
              <ListItemIcon>
                <FaUserCircle className="menu-icon" />
              </ListItemIcon>
              <ListItemText>Users</ListItemText>
            </ListItemButton>: null}

            {(user?.role === "user" || impersonateUserData?.userId) ? <ListItemButton
              button="true"
              onClick={() => {history.push('/sub-users')}}
              selected={window.location.pathname === '/sub-users'}
            >
              <ListItemIcon>
                <FaUsers className="menu-icon" />
              </ListItemIcon>
              <ListItemText>Sub Users</ListItemText>
            </ListItemButton>: null}

            {(user?.role === "user" || impersonateUserData?.userId) ? <ListItemButton
              button="true"
              onClick={() => {history.push('/referrals')}}
              selected={window.location.pathname === '/referrals'}
            >
              <ListItemIcon>
                <FaLink className="menu-icon" />
              </ListItemIcon>
              <ListItemText>Referrals</ListItemText>
            </ListItemButton>: null}

            <ListItemButton
              button="true"
              onClick={() => {history.push('/profile')}}
              selected={window.location.pathname === '/profile'}
            >
              <ListItemIcon>
                <FaUserCircle className="menu-icon" />
              </ListItemIcon>
              <ListItemText>Manage Account</ListItemText>
            </ListItemButton>

            {(user?.role === 'admin' || gcEmailLists.includes(user?.email) && !impersonateUserData?.userId) ? <ListItemButton
              button="true"
              onClick={() => {history.push('/gift-cards')}}
              selected={window.location.pathname === '/gift-cards'}
            >
              <ListItemIcon>
                <FaGift className="menu-icon" />
              </ListItemIcon>
              <ListItemText>Gift Cards</ListItemText>
            </ListItemButton> : null}
            <ListItemButton
              button="true"
              onClick={() => {history.push('/orders')}}
              selected={window.location.pathname === '/orders'}
            >
              <ListItemIcon>
                <FaShoppingCart className="menu-icon" />
              </ListItemIcon>
              <ListItemText>Orders</ListItemText>
            </ListItemButton>

            { impersonateUserData.userId ? <ListItemButton
              button="true"
              className="end-impersonate"
              onClick={() => {handleEndImpersonation()}}
            >
              <ListItemIcon className="empersonat">
                <LuBan className="menu-icon" />
              </ListItemIcon>
              <ListItemText>End Impersonate</ListItemText>
            </ListItemButton> : null }

            <ListItemButton
              button="true"
              className="log-out-class"
              onClick={() => {handleLogout()}}
            >
              <ListItemIcon>
                <IoIosLogOut className="menu-icon"/>
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={open === false ? { ...style, ml: 9.12 } : { ...style, ml: 30 }}
        >
          <header>
          <div style={{paddingTop: '8px', cursor: 'pointer'}} onClick={() => {
                window.open('https://chromewebstore.google.com/detail/qikfinds-extension/hakjiagadepdajkkhjcplkdinlkgakal','_blank')
              }}>
            {!versionDetails.success ? <span>Click to Downloads The Extension</span> : <span><b>Version:</b> {versionDetails.value}</span>}
          </div>
            <div className="header-items">
              <div className="card-overlay-ui">
                <p>{impersonateUserData?.name || user?.name || "N/A"}</p>
                <div className="divider" />
                <p>{impersonateUserData?.email || user?.email || "N/A"}</p>
              </div>
              <p className="user-bag">{userName}</p>
            </div>
          </header>
          <div className="page-ui-wrapper"> {children}</div>
        </Box>
      </Box>
    </SidebarWrapper>
  );
};

export default Layout;
