import React from 'react';

import { ToasterWrapper } from './style';
import CloseIcon from '../../assets/images/closeIcon-blue.svg';
import CloseIconBlue from '../../assets/images/closeIcon.svg';

const Index = (props) => {
  const { header, content, variant, onClose, icon, color } = props;

  return (
    <ToasterWrapper color={color} variant={variant} onClose={onClose} dismissible>
      <img
        src={!color ? CloseIconBlue : CloseIcon}
        onClick={onClose}
        alt="close icon"
        className="close-icon"
      />
      {header && <Alert.Heading>{header}</Alert.Heading>}
      {content && (
        <div className="d-flex">
          {icon && <img src={icon} alt="info icon" className="info-icon" />}{" "}
          {content}
        </div>
      )}
    </ToasterWrapper>
  );
};

export default Index;
